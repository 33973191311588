'use client'

import { useEffect } from 'react'
import { Md5 } from 'ts-md5'

export function initGTM(trackingId?: string) {
  useEffect(() => {
    if (trackingId) {
      // Note: this will fire twice in DEV mode due to react 18 rendering behaviour
      // see here: https://github.com/vercel/next.js/issues/35822
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      const f = document.getElementsByTagName("script")[0]
      const j = document.createElement("script")
      j.async = true
      j.src = 'https://cdn.messika.com/emyny76w4qi84ut.js?aw=' + trackingId.replace(/^GTM-/, '');
      f.parentNode?.insertBefore(j, f);
    }
  }, [])
}

export function createGtmProduct(product: Product | VariantProduct) {
  const storedValues = JSON.parse(window.localStorage.getItem('productValues') as any)

  const gtmCategoryObj = gtmCategory(product?.gtm_categories)

  const productItem = {
    price: product?.price_range?.minimum_price?.regular_price?.value,
    item_name: product?.gtm_name,
    item_id: product?.sku,
    item_brand: 'Messika',
    index: storedValues?.position ?? '',
    item_list_id: storedValues ? storedValues.listId : 'produit',
    item_list_name: storedValues ? storedValues.listName : 'Produit',
    ...gtmCategoryObj,
  }
  return productItem;
}

export function gtmPush(gtmEvent: any) {
  /* @ts-ignore */
  let dataLayerLogger = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    push: (args: Record<string, any>) => ({}),
  }

  /* eslint-disable valid-typeof */
  if (typeof window !== undefined && window.dataLayer) {
    dataLayerLogger = window.dataLayer
  }
  if (process.env.NODE_ENV === "development") {
    console.log(gtmEvent);
  }
  dataLayerLogger.push(gtmEvent)
}

export function gtmCategory(item: string | undefined) {
  const categorieProp = {}
  const categorie = item

  categorie
    ?.split('/')
    .filter((n) => n)
    .map((cat, index) => {
      index === 0
        ? (categorieProp[`item_category`] = cat)
        : (categorieProp[`item_category${index + 1}`] = cat)
    })
    .slice(0, 4)

  return categorieProp
}

export function md5Encrypt(value: (string | number) | undefined | null) {
  return value ? Md5.hashStr(value?.toString()) : ''
}

import { AllWebsitesQuery } from '../queries/AllWebsites.gql'
import { handleError } from '../core/hook/utils'

export interface ShippingZone {
  name: string | null
  code: string | null
  stores: Store[]
}

interface Store {
  name?: string | null | undefined
  code?: string | null | undefined
  locale?: string | null | undefined
  domain?: string | null | undefined
  isActive?: number | null | undefined
}

export const getStoresByShippingZone = (
  shippingZones: ShippingZone[],
  shippingZoneCode: string,
): Store[] => {
  try {
    return shippingZones.find((website) => website.code === shippingZoneCode)?.stores || []
  } catch (error) {
    handleError(error, false)

    return []
  }
}

export const getShippingZoneByStore = (
  shippingZones: ShippingZone[],
  storeCode: string,
): ShippingZone | null => {
  let shippingZoneForStore: ShippingZone | null = null
  shippingZones.forEach((shippingZone) => {
    const storeCodeExist = shippingZone.stores.find((store) => store.code === storeCode)

    if (storeCodeExist) {
      shippingZoneForStore = shippingZone
    }
  })

  return shippingZoneForStore
}

export const prepareWebsitesToShippingZones = (data: AllWebsitesQuery): ShippingZone[] => {
  if (!data.allWebsites) {
    return []
  }

  return data.allWebsites.map((website) => ({
    name: website?.name || null,
    code: website?.code || null,
    stores:
      website?.stores?.map((store) => ({
        name: store?.name || null,
        code: store?.code || null,
        locale: store?.locale || null,
        domain: store?.domain || null,
        isActive: store?.isActive || null,
      })) || [],
  }))
}

export const changeStoreUtils = () => {
  // we need to reset the cart when the user logout
  localStorage.removeItem('cart')
}

export const DefaultShippingZone = 'eu'
export const DefaultStoreCode = process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? 'fr'

export const storeToValidLocale = (store: string) => {
  switch (store) {
    case 'sa_ar':
    case 'ar_ar':
      return 'ar'
    case 'uk_en':
      return 'en-GB'
    case 'us_en':
      return 'en-US'
    case 'tu_en':
    case 'su_en':
    case 'ru_en':
    case 'ci_en':
    case 'ar_en':
    case 'hk_en':
    case 'sa_en':
      return 'en'
    case 'fr':
    case 'ci_fr':
      return 'fr'
    case 'su_fr':
      return 'fr-CH'
    case 'ru_ru':
      return 'ru'
    case 'jp_ja':
      return 'ja'
    case 'ch_cn':
      return 'zh'
    case 'kr_kr':
      return 'ko'
    default:
      return store
  }
}
export const getDefaultShippingZone = (): string => DefaultShippingZone
